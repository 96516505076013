import React, { useEffect } from 'react';
import logo from "../images/logo.svg"
import fox from "../images/fox.svg"
import { Link } from "gatsby"
import { useLocomotiveScroll } from 'react-locomotive-scroll'

const Menu = (props) => {


	const { scroll } = useLocomotiveScroll()
	let left = props.left || "designer";

	let modalClass = "hide";
	if (props.isShowing) {
		modalClass = "show"
	} else {
		modalClass = "hide"
	}

	useEffect(() => {

		if (props.isShowing) {
			if(scroll)
			scroll.stop();
		} else {
			if(scroll)
			scroll.start();
		}
	});


	return (
		<div className={`modal-container ${modalClass}`} aria-modal aria-hidden tabIndex={-1} role="dialog" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction">
			<div className={`modal-wrapper menu-overlay overlay-nav ${modalClass}`}>
				<div className="modal-content max-width p-relative">
					<div className="modal-header">
						<div className="logo-modal--wraper">
							<img className="logo-modal" src={logo} alt="" />
							<div className="left-designer">
								{left}
							</div>
						</div>
					</div>
					<div className="modal-body col justify-center align-center">
						<Link className="menu-overlay__item" to="/" activeClassName="active">
							porfólio
							<span className='line'></span>
						</Link>
						<Link className="menu-overlay__item" to="/about/" activeClassName="active">
							about moi
							<span className='line'></span>
						</Link>
						<Link className="menu-overlay__item" to="/contactos/" activeClassName="active">
							contactos
							<span className='line'></span>
						</Link>

						<Link to="/showreel/" className="menu-overlay__item showreel">
							showreel
							<span className='line'></span>
						</Link>
						<img className="fox-menu mt-50" src={fox} alt="fox" />
					</div>
					<div className="modal-footer">
						<a className="showreel__email" aria-label="Email" href="mailto:helloluisvaz@gmail.com">helloluisvaz@gmail.com</a>
						<a className="showreel__site" aria-label="Website" href="https://www.luisvaz.com/" target="_blank" rel="noreferrer">www.luisvaz.com</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Menu;