import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import favicon from '../images/favicon-16x16.png'
import favicon32 from '../images/favicon-32x32.png'

const Seo = ({ post, location }) => {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            title
            description
						image
						siteUrl
						username
          }
        }
      }
    `
	)

	const lang = "pt";
	const title = post?.title;
	const defaultTitle = site.siteMetadata?.title
	const metaTitle = title ? title + " | " + site.siteMetadata?.title : site.siteMetadata?.title;
	const url = location ? site.siteMetadata.siteUrl + location : site.siteMetadata.siteUrl;


	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			defaultTitle={defaultTitle}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}>
			<meta property="description" content={site.siteMetadata.description} />

			<link rel="icon" href={favicon} />
			<link rel="icon" href={favicon32} />
			<link rel="canonical" href={url} />

			<meta property="og:type" content="website" />
			<meta property="og:url" content={url} />
			<meta property="og:title" content={metaTitle} />
			<meta property="og:description" content={site.siteMetadata.description} />
			<meta property="og:image" content={site.siteMetadata.image} />

			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:url" content={url} />
			<meta property="twitter:title" content={metaTitle} />
			<meta property="twitter:description" content={site.siteMetadata.description} />
			<meta property="twitter:image" content={site.siteMetadata.image} />
			<meta name="twitter:creator" content={site.siteMetadata.username} />

		</Helmet>
	)
}

export default Seo